@font-face { 
  font-family: 'Nanami';
  font-weight: 'light';
  src: url('fonts/Nanami_Rounded_Pro_Thin.otf');
}

.main_img {
  /* min-height:1000px !important; */
  height:100vh;
  background:url('images/004.jpeg') no-repeat bottom center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.header {
  margin: 0 auto !important;
}

.iframe_map {
  width:100vw;
  height:50vh;
}

.logo {
  width:15vw;
  margin: 0 auto !important;
  padding-top:10%;
  padding-bottom:10%;
}

.address {
  font-size:30px;
  text-align:center;
  font-family:'Nanami';
  font-weight:'bolder';
  letter-spacing:0.5px;
}

.socials {
  padding-top: 10%;
}

.social_icons {
  padding-left:10px;
}


@media (min-width: 601px) and (max-width: 900px) {
  .main_img {
    height:70vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat bottom left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .social_icons {
    padding-left:20px;
  }
  .socials {
    padding-top:6%;
  }

  .address {
    font-size:18px;
    text-align:center;
    font-family:'Nanami';
    font-weight:'bolder';
    letter-spacing:0.5px;
  }

  .map {
    width: 100vw;
  }
  
}


@media screen and (max-width: 600px) {

  .main_img {
    height:60vh;
    background: url('images/004.jpeg') no-repeat bottom left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
  }

  .address, .phone {
    text-align: center;
    font-size: 18px; 
    font-weight: 200;
    font-family:'Nanami';
  }

  .header {
    font-size: '12px';
  }

  .map {
    width:100vw;
    height:20vh;
  }

  .socials {
    padding-top:5%;
  }

  .social_icons {
    padding-left:0 !important;
    height:2.5vh !important;
    /* border:1px solid black; */
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .social_icons {
    height: 4vh;
    padding-right:5%;
  }
}

@media (min-width: 901px) and (max-width: 1600px) {
  .main_img {
    height:40vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat bottom left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size:cover;
  }
}

@media (min-width: 1101px) and (max-width: 1400px) {
  .main_img {
    height:50vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size:cover;
  }
}

@media (min-width: 1401px) {
  .logo {
    width:10vw;
  }
  
  .main_img {
    height:50vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 1601px) {
  .main_img {
    height:60vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 1801px) {
  .main_img {
    height:70vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 2700px) {
  .main_img {
    height:80vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 3000px) {
  .main_img {
    height:90vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (min-width: 3200px) {
  .main_img {
    height:100vh;
    width:100vw;
    background:url('images/004.jpeg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}